import Clickable from '/components/Clickable';
import Icon from '/components/IconUpdated';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const QuantitySelector = ({
	copy,
	currentQuantity,
	incrementQuantity,
	minimumQuantity,
	updateQuantity,
}) => {
	return (
		<div className="mb-4 w-full py-2">
			<Typography className="mb-2 text-left" component="div" variant="heading-xs">
				Quantity
			</Typography>
			<div className="flex gap-2">
				<Clickable
					className="bg-v2-off-white flex h-[46px] items-center justify-center rounded-full w-[46px]"
					onClick={() => {
						incrementQuantity(-1);
					}}>
					<Icon name="minus" height="14px" width="14px" />
				</Clickable>
				<input
					className="h-[46px] p-2 rounded-lg text-center w-1/5 bg-v2-off-white"
					onChange={(event) => {
						updateQuantity(event);
					}}
					min={minimumQuantity}
					type="text"
					value={currentQuantity}
				/>
				<Clickable
					className="bg-v2-off-white flex h-[46px] items-center justify-center rounded-full w-[46px]"
					onClick={() => {
						incrementQuantity(1);
					}}>
					<Icon name="plus" height="14px" width="14px" />
				</Clickable>
			</div>
			{copy && (
				<Typography className="italic mt-2 text-gray-600" component="div" variant="small">
					{copy}
				</Typography>
			)}
		</div>
	);
};

QuantitySelector.propTypes = {
	copy: PropTypes.string,
	currentQuantity: PropTypes.number.isRequired,
	incrementQuantity: PropTypes.func.isRequired,
	minimumQuantity: PropTypes.number.isRequired,
	updateQuantity: PropTypes.func.isRequired,
};

export default QuantitySelector;
