import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getButtonImageSrc } from '/services/products';
import { getSimilarPrints as getSimilarPrintsAlgolia } from '/services/algolia';
import { getTestVariant } from '/services/ab-test';
import { useEffect, useState } from 'react';

const PrintSelector = ({ product, size }) => {
	const [printOptions, setPrintOptions] = useState([]);

	useEffect(() => {
		if (!product) {
			return;
		}

		async function fetchSimilarPrints() {
			const result = await getSimilarPrintsAlgolia(product, null, size);
			setPrintOptions(result);
		}

		fetchSimilarPrints();
		document.getElementById('scroll-section').scrollLeft -= 1000;
	}, [product, size]);

	return (
		<div className="mb-6">
			<div className="h-[70px] overflow-x-scroll overflow-y-hidden md:hidden" id="scroll-section">
				<div
					className="flex pt-1 pl-1  gap-2"
					style={{
						width: String(printOptions.length < 50 ? printOptions.length * 65 : 50 * 65) + 'px',
					}}>
					{printOptions.map((print, i) => {
						const buttonImage = getButtonImageSrc(print);
						if (!buttonImage) {
							return null;
						}
						return (
							<Clickable
								key={i}
								linkToInternal={'/products/' + print.handle}
								className={classNames(
									'rounded-full h-[50px] w-[50px] m-px',
									product.handle === print.handle
										? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
										: 'hover:ring-v2-brown hover:border-2 border-white hover:ring-4',
								)}>
								<Image
									src={buttonImage}
									height={200}
									width={200}
									objectFit="contain"
									alt="button image"
								/>
							</Clickable>
						);
					})}
				</div>
			</div>
			<div className="flex-wrap rounded-b-lg justify-left hidden md:flex">
				{printOptions.map((print, i) => {
					const buttonImage = getButtonImageSrc(print);
					if (!buttonImage) {
						return null;
					}
					return (
						<Clickable
							key={i}
							linkToInternal={'/products/' + print.handle}
							className={classNames(
								'overflow-hidden rounded-full m-1 h-12 w-12 hover:ring-v2-brown hover:border-2 border-white hover:ring-4',
								product.handle === print.handle
									? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker '
									: '',
							)}>
							<Image src={buttonImage} height={200} width={200} alt="button image" />
						</Clickable>
					);
				})}
			</div>
		</div>
	);
};

const currentTheme = ' new';

const PrintSelectorBuckets = ({ product, setActiveProductHandle, size }) => {
	const [activeFlavor, setActiveFlavor] = useState();
	const [printOptions, setPrintOptions] = useState([]);
	const [showNewBadge, setShowNewBadge] = useState(false);

	useEffect(() => {
		const print = product?.tags.find((element) => {
			return element.includes('print:');
		});

		const processedPrint = print?.replace('print:', '') || ' Solids';
		setActiveFlavor(processedPrint);
	}, [product]);

	useEffect(() => {
		if (!product || !activeFlavor) {
			return;
		}

		async function fetchSimilarPrints() {
			const result = await getSimilarPrintsAlgolia(product, activeFlavor, size);

			const index = result.findIndex((item) => {
				return item.handle === product.handle;
			});

			if (index && index > 14) {
				const product = result[index];
				delete result[index];

				result.unshift({
					auxiliaryImages: {
						buttonSrc: product?.auxiliaryImages?.buttonSrc,
					},
					image: product.image,
					handle: product.handle,
				});
			}

			setPrintOptions(result);
		}

		fetchSimilarPrints();
		document.getElementById('scroll-section').scrollLeft -= 1000;
	}, [activeFlavor, product, size]);

	useEffect(async () => {
		const variation = await getTestVariant('seasonal-new-badge');
		setShowNewBadge(variation.key === 'show-new-badge');
	}, []);

	return (
		<div className="mb-6">
			<div className="flex justify-left mb-4 w-full gap-2">
				<Clickable
					className={classNames(
						'h-[46px] capitalize font-bold tracking-wider w-1/3 text-v2-brown-darker',
						activeFlavor === ' Solids'
							? 'border-2 border-v2-brown-darker rounded-full'
							: 'hover:rounded-full hover:border-2 hover:border-gray-500',
					)}
					onClick={() => setActiveFlavor(' Solids')}>
					<Typography component="p" variant="body">
						Solids
					</Typography>
				</Clickable>
				<Clickable
					className={classNames(
						'h-[46px] capitalize font-bold tracking-wider  w-1/3 text-v2-brown-darker',
						activeFlavor === ' Patterned'
							? 'v2-brown-darker border-2 border-v2-brown-darker rounded-full'
							: 'hover:rounded-full hover:border-2 hover:border-gray-500 t',
					)}
					onClick={() => setActiveFlavor(' Patterned')}>
					<Typography component="p" variant="body">
						Patterns
					</Typography>
				</Clickable>
				{!product?.productType?.includes('Socks') && !product?.productType?.includes('Bralette') && (
					<Clickable
						className={classNames(
							'h-[46px] capitalize font-bold tracking-wider  w-1/3 text-v2-brown-darker',
							activeFlavor === ' Apex'
								? ' v2-brown-darker border-2 border-v2-brown-darker rounded-full'
								: 'hover:rounded-full hover:border-2 hover:border-gray-500',
						)}
						onClick={() => setActiveFlavor(' Apex')}>
						<Typography component="p" variant="body">
							Crotch shots
						</Typography>
					</Clickable>
				)}
			</div>

			<div className="h-[70px] overflow-x-scroll overflow-y-hidden md:hidden" id="scroll-section">
				<div
					className="flex pt-1 pl-1  gap-2"
					style={{
						width: String(printOptions.length < 50 ? printOptions.length * 65 : 50 * 65) + 'px',
					}}>
					{printOptions.map((print, i) => {
						const buttonImage = getButtonImageSrc(print);
						if (buttonImage && i < 50) {
							if (product.setActiveProductHandle) {
								return (
									<Clickable
										key={i}
										onClick={() => setActiveProductHandle(print.handle)}
										className={classNames(
											'rounded-full h-[50px] w-[50px] m-px',
											product.handle === print.handle
												? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
												: '',
										)}>
										<Image
											src={buttonImage}
											height={200}
											width={200}
											objectFit="contain"
											alt="button image"
										/>
									</Clickable>
								);
							}
							return (
								<Clickable
									key={i}
									linkToInternal={'/products/' + print.handle}
									className={classNames(
										'rounded-full h-[50px] w-[50px] m-1 relative',
										product.handle === print.handle
											? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
											: '',
									)}>
									{print?.named_tags?.capsule?.includes(currentTheme) && showNewBadge && (
										<div className="absolute text-white bg-primary z-20 rounded-full px-1 -mt-[8px]">
											New
										</div>
									)}
									<Image
										src={buttonImage}
										height={200}
										width={200}
										objectFit="contain"
										alt="button image"
									/>
								</Clickable>
							);
						}
					})}
				</div>
			</div>

			<div className="flex-wrap rounded-b-lg justify-left hidden md:flex">
				{printOptions.map((print, i) => {
					const buttonImage = getButtonImageSrc(print);

					if (buttonImage && i < 50) {
						if (setActiveProductHandle) {
							return (
								<Clickable
									key={i}
									onClick={() => setActiveProductHandle(print.handle)}
									className={classNames(
										'rounded-full h-[50px] w-[50px] m-1',
										product.handle === print.handle
											? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
											: '',
									)}>
									<Image
										src={buttonImage}
										height={200}
										width={200}
										objectFit="contain"
										alt="button image"
									/>
								</Clickable>
							);
						}
						return (
							<Clickable
								key={i}
								linkToInternal={'/products/' + print.handle}
								className={classNames(
									'overflow-hidden rounded-full m-1 h-12 w-12 hover:ring-v2-brown hover:border-2 border-white hover:ring-4',
									product.handle === print.handle
										? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker '
										: '',
								)}>
								{print?.named_tags?.capsule?.includes(currentTheme) && showNewBadge && (
									<div className="absolute text-white bg-primary z-20 rounded-full px-1 -mt-[8px]">
										New
									</div>
								)}
								<Image src={buttonImage} height={200} width={200} alt="button image" />
							</Clickable>
						);
					}
				})}
			</div>
		</div>
	);
};

module.exports = {
	PrintSelector,
	PrintSelectorBuckets,
};

PrintSelector.propTypes = {
	product: PropTypes.object,
	size: PropTypes.string,
};

PrintSelectorBuckets.propTypes = {
	product: PropTypes.object,
	setActiveProductHandle: PropTypes.func,
	size: PropTypes.string,
};
