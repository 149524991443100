import PropTypes from 'prop-types';
//to-do theres probably a better place to put these

const filterRenames = {
	bikini: 'Bikinis',
	'bikini lace': 'Lace Bikinis',
	'blazers - nfl': 'NFL Blazers',
	'bow ties - nfl': 'NFL Bow Ties',
	boxers: 'Boxer Briefs',
	'boxers - brief': 'Briefs',
	'boxers - cooling - fly': 'Cooling Boxer Briefs',
	'boxers - cooling - long': 'Cooling Long Boxer Briefs',
	'boxers - fly': 'Boxer Briefs w/ Fly',
	'boxers - long - fly': 'Long Boxer Briefs w/ Fly',
	'boxers - trunk': 'Trunks',
	boyshort: 'Boyshorts',
	'bralettes - cooling': 'Cooling Bralettes',
	'conjured subscription': 'Underwear Subscription',
	"custom men's sweaters": 'Branded Sweaters',
	'custom onesies': 'Branded Onesies',
	'fur coat': 'Fur Coats',
	gifts: 'Stocking Stuffers',
	"men's fleece": 'Fleece Jackets',
	"men's hygiene": 'Grooming',
	"men's jackets": 'Jackets',
	"men's ls knits": 'Long Sleeve Shirts',
	"men's overalls": 'Overalls',
	"men's ls wovens": 'Button Downs',
	"men's ss knits": 'Short Sleeve Shirts',
	"men's ss wovens": 'Hawaiians',
	"men's ss wovens - NFLPA": 'NFL Hawaiians',
	"men's shorts": 'Shorts',
	"men's sweaters": 'Sweaters',
	'mixed packs': 'Couples Underwear Packs',
	'no show bikini - high waist': 'High Waisted Bikinis',
	'no show thong - high waist': 'High Waisted Thongs',
	'onesies - nhl': 'NHL Onesies',
	'overalls - nfl': 'NFL Overalls',
	promotional: 'Extras',
	'ss blazers': 'Short Sleeve Suits',
	subscription: 'Underwear Subscription',
	'suit pants - nfl': 'NFL Suit Pants',
	'thong lace': 'Lace Thongs',
	'thongs - cooling': 'Cooling Thongs',
	'thongs - modal': 'Modal Thongs',
	'thongs - top lace': 'Top Lace Thongs',
	'ties - nfl': 'NFL Ties',
	trunks: 'Swim Trunks',
	"women's fleece": "Women's Fleece Jackets",
	"women's ss wovens": "Women's Hawaiians",
};

function CheckboxGroup({
	label = '',
	name = '',
	checked = false,
	disabled = false,
	onChange = () => {},
	...rest
}) {
	let labelClasses = 'flex';
	let svgClasses = 'fill-current w-5 h-5 bg-primary pointer-events-none';
	let divClasses =
		'outline-none bg-v2-off-white border rounded-sm w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2';
	let inputClasses = 'appearance-none outline-0 opacity-0 absolute w-[18px] h-[18px]';

	if (!checked) {
		divClasses += '  border-gray-600';
		svgClasses += ' hidden';
		if (!disabled) {
			inputClasses +=
				' focus:bg-gray-300 focus:opacity-100 focus:ml-[1px] focus:mt-[1px] md:hover:bg-gray-300 md:hover:opacity-100 md:hover:ml-[1px] md:hover:mt-[1px]';
		}
	} else {
		divClasses += ' border-primary';
	}

	if (disabled) {
		labelClasses += ' cursor-not-allowed opacity-50';
		divClasses += ' opacity-50';
	}
	return (
		<div className="relative flex items-center mr-4 mb-2">
			<label className={labelClasses} htmlFor={name}>
				<input
					type="checkbox"
					id={name}
					checked={checked}
					disabled={disabled}
					onKeyPress={(e) => {
						e.code === 'Enter' && onChange(!e.currentTarget.checked);
					}}
					onChange={(e) => onChange(e.currentTarget.checked)}
					className={inputClasses}
					{...rest}
				/>
				<div className={divClasses}>
					<svg
						className={svgClasses}
						version="1.1"
						viewBox="0 0 17 12"
						xmlns="http://www.w3.org/2000/svg">
						<g fill="none" fillRule="evenodd">
							<g transform="translate(-9 -11)" fill="#ffffff" fillRule="nonzero">
								<path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
							</g>
						</g>
					</svg>
				</div>
				{filterRenames[label?.props?.children.toLowerCase()] || label}
			</label>
		</div>
	);
}

CheckboxGroup.propTypes = {
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
	name: PropTypes.string,
	onChange: PropTypes.func,
};

export default CheckboxGroup;
