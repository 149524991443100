import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { useEffect, useState } from 'react';

function RadioGroup({
	cartType,
	className = '',
	name,
	radios = [],
	onChange = () => {},
	error = false,
	errorText = '',
	description = '',
	sellingPlan,
	...newProps
}) {
	const [showSubOptions, setShowSubOptions] = useState(true);

	const hasError = error || errorText;
	let finalClass = `${className}`;
	if (hasError) {
		finalClass += ' border-red-600';
	}

	useEffect(() => {
		if (showSubOptions) {
			onChange(radios[2].value);
		} else {
			onChange(radios[0].value);
		}
	}, [showSubOptions]);

	function isSelected(r) {
		return cartType != 'product' ? r.value.includes(sellingPlan?.id) : r.value == 'product';
	}

	let radiosWrapperClass = 'flex gap-2';
	let radioWrapperClass = 'flex items-center';

	const Radios = radios.map((r) => (
		<div key={`${r.value}-${name}`} className={radioWrapperClass}>
			<label className="cursor-pointer text-base flex flex-col" htmlFor={`${r.value}-${name}`}>
				<fieldset>
					<div
						className={classNames(
							'px-3 rounded-md flex items-center align-middle text-center h-12 md:py-4 border-2 border-v2-brown-darker',
							isSelected(r)
								? 'bg-v2-brown  text-v2-off-white'
								: 'bg-v2-off-white text-v2-brown-darker',
						)}>
						<input
							name={name}
							value={r.value}
							type="radio"
							className="hidden"
							id={`${r.value}-${name}`}
							onChange={(e) => onChange(e.currentTarget.value)}
							defaultChecked={r.checked}
						/>
						{r.label}
					</div>
				</fieldset>
			</label>
		</div>
	));
	const subRadios = Radios.slice(1, Radios.length);

	return (
		<div className={finalClass} {...newProps}>
			<>
				<div className="mt-1 md:mt-2">
					<Typography variant="heading-xs">Purchase Options</Typography>
				</div>
				<div className="inline-flex gap-2 border border-v2-brown-darker rounded-md p-1">
					<button
						className={classNames(
							'px-4 py-3 rounded-md border-2 border-v2-brown-darker',
							showSubOptions
								? 'bg-v2-brown text-v2-off-white'
								: 'bg-v2-off-white text-v2-brown-darker',
						)}
						onClick={() => setShowSubOptions(true)}
						type="button">
						<Typography variant="heading-xs">Subscribe</Typography>
					</button>
					<button
						className={classNames(
							'px-4 py-3 rounded-md border-2 border-v2-brown-darker',
							!showSubOptions
								? 'bg-v2-brown text-v2-off-white'
								: 'bg-v2-off-white text-v2-brown-darker',
						)}
						onClick={() => setShowSubOptions(false)}
						type="button">
						<Typography variant="heading-xs">One-Time Buy</Typography>
					</button>
				</div>
				{showSubOptions && (
					<div className={radiosWrapperClass}>
						<div>
							<div className="my-2">
								<Typography variant="heading-xs">Choose your frequency</Typography>
							</div>
							<div className="flex gap-2">{subRadios}</div>
							<div className="py-2 text-center">
								<Typography variant="body">
									<span className="line-through text-gray-500">15.99</span> 11.99 per shipment,
									cancel anytime.
								</Typography>
							</div>
						</div>
					</div>
				)}
			</>

			{description && <span className="mt-2 text-v2-brown-darker text-xs">{description}</span>}
			{errorText && (
				<div className="bg-red-200 mt-2 py-2 px-4 text-xs text-primary rounded-md">{errorText}</div>
			)}
		</div>
	);
}

RadioGroup.propTypes = {
	cartType: PropTypes.string,
	className: PropTypes.string,
	description: PropTypes.string,
	error: PropTypes.bool,
	errorText: PropTypes.string,
	horizontal: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	radios: PropTypes.array.isRequired,
	sellingPlan: PropTypes.object,
};

export default RadioGroup;
